import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Injectable,
  Injector,
  TemplateRef,
} from '@angular/core';
import { SidebarComponent } from './sidebar.component';

export interface SidebarComponentInputs<T = any> {
  hideHeader?: boolean;
  animation?: boolean;
  cancelable?: boolean;
  noPadding?: boolean;
  fullHeight?: boolean;
  xOffset?: number;
  title?: string | TemplateRef<T>;
}

export interface SidebarOptions<T = any, T2 = any> extends SidebarComponentInputs<T2> {
  component?: T;
  componentInputs?: Record<keyof T, T[keyof T]>;
}
@Injectable()
export class SidebarService {
  private readonly sidebarBodyClass = 'sidebar-opened';

  private componentRef: ComponentRef<SidebarComponent>;
  #opened: boolean = false;

  get opened(): boolean {
    return this.#opened;
  }

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private applicationRef: ApplicationRef,
    private injector: Injector
  ) {}

  public open(options: SidebarOptions): ComponentRef<SidebarComponent> {
    if (this.#opened) {
      return;
    }

    this.#opened = true;

    this.setBodySidebarActiveClass();
    this.createSidebarContainer();
    this.applyOptions(options);
    return this.componentRef;
  }

  public toggle(options: SidebarOptions): ComponentRef<SidebarComponent> | null {
    if (this.#opened) {
      this.close();
      return;
    }
    return this.open(options);
  }

  public close(): void {
    if (!this.#opened) {
      return;
    }
    this.#opened = false;
    this.setBodySidebarActiveClass();
    this.applicationRef.detachView(this.componentRef.hostView);
    this.componentRef.destroy();
  }

  private setBodySidebarActiveClass(): void {
    if (this.#opened) {
      document.body.classList.add(this.sidebarBodyClass);
      return;
    }
    document.body.classList.remove(this.sidebarBodyClass);
  }

  private createSidebarContainer(): void {
    this.componentRef = this.componentFactoryResolver.resolveComponentFactory(SidebarComponent).create(this.injector);
    this.applicationRef.attachView(this.componentRef.hostView);
    const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);
  }

  private applyOptions(options: SidebarOptions): void {
    this.componentRef.instance.title = options.title;
    this.componentRef.instance.contentComponent = options.component;
    this.componentRef.instance.contentComponentInputs = options.componentInputs;
    this.componentRef.instance.cancelable = options.cancelable;
    this.componentRef.instance.noPadding = options.noPadding;
    this.componentRef.instance.fullHeight = options.fullHeight ?? true;
    this.componentRef.instance.hideHeader = options.hideHeader ?? false;
    this.componentRef.instance.animation = options.animation ?? false;
    this.componentRef.instance.xOffset = options.xOffset ?? 0;
  }
}
