<div
  class="sidebar-wrapper"
  [ngClass]="sidebarPosition"
  [class.animated]="animation"
  [class.no-padding]="noPadding"
  [class.full-height]="fullHeight"
  [ngStyle]="{ width: sidebarWidth + 'px' }"
  style="--top-offset: {{ headerOffset }}px; --x-offset: {{ xOffset }}px;"
>
  <div *ngIf="!hideHeader" class="sidebar-header">
    <div *ngIf="titleText; else titleViewTemplate" class="sidebar-title">
      {{ titleText }}
    </div>
    <app-action-icon *ngIf="cancelable" (click)="close()" icon="clear" theme="subtle"></app-action-icon>

    <ng-template #titleViewTemplate>
      <ng-container [ngTemplateOutlet]="titleTemplate"></ng-container>
    </ng-template>
  </div>

  <div class="sidebar-content">
    <ng-container #sidebarContentTemplate></ng-container>
  </div>
</div>
