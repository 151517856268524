import { DOCUMENT, NgClass, NgStyle, NgIf, NgTemplateOutlet } from '@angular/common';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Inject,
  AfterViewInit,
  OnDestroy,
  ViewContainerRef,
  EventEmitter,
  ViewChild,
  ComponentRef,
  TemplateRef,
} from '@angular/core';
import { SidebarComponentInputs, SidebarService } from './sidebar.service';
import { fromEvent, Subject, takeUntil } from 'rxjs';
import { ActionIconComponent } from '@shared/components/action-icon/action-icon.component';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, NgStyle, NgIf, ActionIconComponent, NgTemplateOutlet],
})
export class SidebarComponent implements OnInit, AfterViewInit, OnDestroy, SidebarComponentInputs {
  /*
   * Initial opened status
   */
  @Input() isOpen: boolean = false;

  /*
   *  Could sidebar close itself?
   */
  @Input() cancelable: boolean = true;

  /*
   * Disable default padding
   */
  @Input() noPadding: boolean = false;

  /*
   *  Sidebar title
   */
  @Input() set title(value: string | TemplateRef<any>) {
    if (value instanceof TemplateRef) {
      this.titleTemplate = value;
      return;
    }
    this.titleText = value;
  }

  /*
   *  Should the sidebar take the height of the header into account?
   */
  @Input() respectHeader: boolean = true;

  @Input() sidebarPosition: 'left' | 'right' = 'right';

  @Input() sidebarWidth: number = 380;

  /*
   *  Should the sidebar fill the entire height of the window?
   */
  @Input() fullHeight: boolean = true;

  @Input() contentComponent: FunctionConstructor;

  @Input() contentComponentInputs: { [key: string]: any };

  @Input() public hideHeader: boolean = false;

  @Input() public animation: boolean = false;

  @Input() public xOffset: number = 0;

  @ViewChild('sidebarContentTemplate', { read: ViewContainerRef, static: true })
  sidebarContentTemplate: ViewContainerRef;

  public headerOffset = 0;

  private readonly destroyed$: Subject<void> = new Subject<void>();
  public closed: EventEmitter<void> = new EventEmitter<void>();
  public contentComponentInstance: ComponentRef<any>;
  public titleText: string;
  public titleTemplate: TemplateRef<any>;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly sidebarService: SidebarService,
    private readonly viewRef: ViewContainerRef
  ) {}

  ngOnInit(): void {
    this.setHeightOffset();
    this.initContentComponent();
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.watchOutsideClickListeners());
  }

  public close(): void {
    this.sidebarService.close();
    this.closed.emit();
  }

  private watchOutsideClickListeners(): void {
    fromEvent(this.viewRef.element.nativeElement as HTMLHtmlElement, 'click')
      .pipe(takeUntil(this.destroyed$))
      .subscribe((event: MouseEvent) => {
        event.stopPropagation();
      });

    fromEvent(this.document, 'click')
      .pipe(takeUntil(this.destroyed$))
      .subscribe((event: MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        this.close();
      });
  }

  private setHeightOffset(): void {
    if (!this.respectHeader) {
      return;
    }
    this.headerOffset ||= this.document.getElementById('layout-header')?.offsetHeight;
  }

  private initContentComponent(): void {
    if (!this.contentComponent) {
      return;
    }
    this.contentComponentInstance = this.sidebarContentTemplate.createComponent(this.contentComponent);
    if (!this.contentComponentInputs) {
      return;
    }
    Object.keys(this.contentComponentInputs).forEach((key) => {
      this.contentComponentInstance.instance[key] = this.contentComponentInputs[key];
    });
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
    this.destroyContentComponent();
  }

  private clearSubscriptions(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private destroyContentComponent(): void {
    if (this.contentComponentInstance) {
      this.contentComponentInstance.destroy();
    }
  }
}
